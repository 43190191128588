/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import resultsTableData from "layouts/tables/data/resultsTableData";
import { useEffect, useState } from "react";

function Tables() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [games, setGames] = useState([]);

  useEffect(() => {
    fetch(
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vQxF8Qg45a7cAV6uOQlJLhKK4eb4IicZah-9JuziYJxopK4ol5He4n7G2-nqaLXxbd2d8CiEO13vPSF/pub?gid=0&single=true&output=csv"
    )
      .then((res) => res.text())
      .then((result) => {
        const tmpGames = [];
        result
          .split("Data")
          .filter((s, index) => index > 0)
          .forEach((s) => {
            const game = {};
            game.result = [];
            // eslint-disable-next-line no-shadow
            const rows = s.split("\n");
            if (rows[rows.length - 1] === "") {
              rows.pop();
            }
            game.date = rows[0].replaceAll(",", "").replace("\r", "");

            // eslint-disable-next-line no-plusplus
            for (let i = 1; i < rows.length; i++) {
              const playerResultArray = rows[i].replace("\r", "").split(",");
              const playerResult = {
                player: playerResultArray[0].toLowerCase(),
                revenue: Number(playerResultArray[1]),
                entry: Number(playerResultArray[2]),
              };
              game.result.push(playerResult);
            }
            tmpGames.push(game);
          });
        return tmpGames;
      })
      .then((tmpGames) => setGames(tmpGames))
      .then(() => setIsLoaded(true));
  }, []);

  if (!isLoaded) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {games
            .sort((a, b) => (b.date > a.date ? 1 : -1))
            .map((game) => (
              <Grid item xs={12} key={game.date}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      {game.date}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={resultsTableData(game)}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            ))}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
