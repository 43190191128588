function names(player) {
  const myMap = new Map();
  myMap.set("kaczor", "Kaczor");
  myMap.set("tasior", "Tasior");
  myMap.set("olo", "Olo");
  myMap.set("bob", "Bob");
  myMap.set("szarpul", "Szarpul");
  myMap.set("ufo", "Ufo");

  return myMap.get(player);
}

export default names;
