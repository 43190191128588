function nicknames(player) {
  const myMap = new Map();
  myMap.set("kaczor", "Kakałko");
  myMap.set("tasior", "Suchy");
  myMap.set("olo", "Bolt");
  myMap.set("bob", "Kierwonik");
  myMap.set("szarpul", "Admin");
  myMap.set("ufo", "Lasy Państwowe");

  return myMap.get(player);
}

export default nicknames;
