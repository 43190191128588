/* eslint-disable react/prop-types */

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// eslint-disable-next-line import/extensions,import/no-absolute-path
import nicknames from "./nicknames";
import playerImage from "./images";

// Images
// import logoXD from "assets/images/small-logos/logo-xd.svg";
// import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
// import logoSlack from "assets/images/small-logos/logo-slack.svg";
// import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
// import logoJira from "assets/images/small-logos/logo-jira.svg";
// import logoInvesion from "assets/images/small-logos/logo-invision.svg";

export default function data(game) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ amount, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {amount}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Zawodnik", accessor: "player", width: "40%", align: "left" },
      { Header: "Dochód", accessor: "revenue", align: "left" },
      { Header: "Wejscie", accessor: "entry", align: "left" },
      { Header: "Profit", accessor: "profit", align: "left" },
    ],
    rows: game.result.map((rowData) => ({
      player: (
        <Author
          image={playerImage(rowData.player)}
          name={rowData.player.charAt(0).toUpperCase() + rowData.player.slice(1)}
          email={nicknames(rowData.player)}
        />
      ),
      revenue: <Job amount={`${rowData.revenue} PLN`} />,
      entry: <Job amount={`${rowData.entry} PLN`} />,
      profit: <Job amount={`${rowData.revenue - rowData.entry} PLN`} />,
    })),
  };
}
