import { Card, Container, Grid, Stack } from "@mui/material";
import Divider from "@mui/material/Divider";
import GaugeChart from "react-gauge-chart";
import { useEffect, useState } from "react";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import MDTypography from "../../components/MDTypography";
import MDAvatar from "../../components/MDAvatar";
import DataLine from "./dataLine";
import playerImage from "../tables/data/images";
import nicknames from "../tables/data/nicknames";
import names from "../tables/data/names";

function stats() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [players, setPlayers] = useState(new Map());

  useEffect(() => {
    fetch(
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vQxF8Qg45a7cAV6uOQlJLhKK4eb4IicZah-9JuziYJxopK4ol5He4n7G2-nqaLXxbd2d8CiEO13vPSF/pub?gid=0&single=true&output=csv"
    )
      .then((res) => res.text())
      .then((result) => {
        const tmpPlayers = new Map();
        result
          .split("Data")
          .filter((s, index) => index > 0)
          .forEach((s) => {
            // eslint-disable-next-line no-shadow
            const rows = s.split("\n");
            if (rows[rows.length - 1] === "") {
              rows.pop();
            }

            // eslint-disable-next-line no-plusplus
            for (let i = 1; i < rows.length; i++) {
              const playerResultArray = rows[i].replace("\r", "").split(",");
              const player = playerResultArray[0].toLowerCase();
              const revenue = Number(playerResultArray[1]);
              const entry = Number(playerResultArray[2]);

              if (!tmpPlayers.has(player)) {
                tmpPlayers.set(player, {
                  name: player,
                  wins: 0,
                  games: 0,
                  revenue: 0,
                  entry: 0,
                  profit: 0,
                });
              }

              if (i === 1) {
                // eslint-disable-next-line no-plusplus
                tmpPlayers.get(player).wins++;
              }

              // eslint-disable-next-line no-plusplus
              tmpPlayers.get(player).games++;
              tmpPlayers.get(player).profit = tmpPlayers.get(player).profit + revenue - entry;
              tmpPlayers.get(player).revenue = tmpPlayers.get(player).revenue + revenue;
              tmpPlayers.get(player).entry = tmpPlayers.get(player).entry + entry;
            }
          });
        return tmpPlayers;
      })
      .then((tmpPlayers) => setPlayers(tmpPlayers))
      .then(() => setIsLoaded(true));
  }, []);

  if (!isLoaded) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
      </DashboardLayout>
    );
  }

  const selectColor = (value) => {
    if (value < 0) {
      return "error";
    }
    return "success";
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Container
        sx={{
          p: {
            xs: 0.5,
            md: 5,
          },
        }}
      >
        <Grid container spacing={5} alignItems="center">
          {Array.from(players).map(([, value]) => (
            <Grid item xs={12} lg={6}>
              <Card
                sx={{
                  p: {
                    xs: 1.5,
                    md: 2.5,
                  },
                  maxWidth: 450,
                }}
              >
                <Stack spacing={2.3}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{ pr: 1 }}
                  >
                    <Stack spacing={2} direction="row" alignItems="center">
                      <MDAvatar src={playerImage(value.name)} name="olo" size="lg" />
                      <Stack direction="column" justifyContent="space-around">
                        <MDTypography lineHeight={1} variant="h5">
                          {names(value.name)}
                        </MDTypography>
                        <MDTypography variant="caption">{nicknames(value.name)}</MDTypography>
                      </Stack>
                    </Stack>
                    <Stack justifyContent="center" alignItems="flex-end">
                      <MDTypography variant="overline">Profit</MDTypography>
                      <MDTypography
                        lineHeight={0.5}
                        fontWeight="bold"
                        color={selectColor(value.profit)}
                        variant="h5"
                      >
                        {value.profit} PLN
                      </MDTypography>
                    </Stack>
                  </Stack>

                  <Divider />
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                    <Grid container>
                      <Grid item xs={6} sm={12}>
                        <DataLine icon="people" label="Liczba gier" value={value.games} />
                      </Grid>
                      <Grid item xs={6} sm={12}>
                        <DataLine
                          icon="military_tech_outlined"
                          label="Wygrane"
                          value={value.wins}
                        />
                      </Grid>
                      <Grid item xs={6} sm={12}>
                        <DataLine icon="tag" label="Wkład" value={value.entry} />
                      </Grid>
                      <Grid item xs={6} sm={12}>
                        <DataLine icon="star" label="Przychód" value={value.revenue} />
                      </Grid>
                    </Grid>
                    <GaugeChart
                      id="gauge-chart4"
                      nrOfLevels={30}
                      colors={["#e12c2c", "#f57c19", "#00b703"]}
                      arcPadding={0.05}
                      cornerRadius={3}
                      hideText="true"
                      needleColor="rgb(56 56 56 / 60%)"
                      textColor="rgb(56 56 56 / 90%)"
                      percent={value.revenue / (value.revenue + value.entry)}
                    />
                  </Stack>
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </DashboardLayout>
  );
}

export default stats;
