import Icon from "@mui/material/Icon";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";

function DataLine(props) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDTypography lineHeight={1} sx={{ mt: 0.15, mr: 0.9 }}>
        {/* eslint-disable-next-line react/destructuring-assignment,react/prop-types */}
        <Icon>{props.icon}</Icon>
      </MDTypography>
      <MDTypography variant="body2" fontWeight="bold">
        {/* eslint-disable-next-line react/prop-types,react/destructuring-assignment */}
        {props.label}:&nbsp;
      </MDTypography>
      {/* eslint-disable-next-line react/destructuring-assignment,react/prop-types */}
      <MDTypography variant="body2">{props.value}</MDTypography>
    </MDBox>
  );
}

export default DataLine;
