import szarpul from "assets/images/players/szarpul.png";
import bob from "assets/images/players/bob.png";
import olo from "assets/images/players/olo.png";
import tasior from "assets/images/players/tasior.png";
import kaczor from "assets/images/players/kaczor.png";
import ufo from "assets/images/players/ufo.jpg";

function playerImage(player) {
  const myMap = new Map();
  myMap.set("kaczor", kaczor);
  myMap.set("tasior", tasior);
  myMap.set("olo", olo);
  myMap.set("bob", bob);
  myMap.set("szarpul", szarpul);
  myMap.set("ufo", ufo);

  return myMap.get(player);
}

export default playerImage;
